<template>
  <div id="CaesarsForum">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>
  
  <script>
import WhereWeAreMap from "@/components/global/WhereWeAreMap";
import { getVenetianMapData } from "@/data/sectionData/whereWeAreData/venetianMapData";

export default {
  name: "CaesarsForum",
  components: {
    WhereWeAreMap,
  },
  data() {
    return {
      mapData: getVenetianMapData(),
    };
  },
};
</script>
  
  <style lang="scss scoped">
</style>